<template>
  <div>
    <label>
      Check access:
      <input placeholder="address" v-model="address" />
    </label>
    <div v-if="isChecked">
      <span v-if="!res" style="color: red">Address is not accessed</span>
      <span v-if="res" style="color: green">Address is accessed</span>
    </div>
    <button @click="send">Get</button>
  </div>
</template>

<script>
import web3, {storageContract} from "@/web3";
import {STORAGE_ADDRESS} from "@/config";

export default {
  name: 'CheckAccess',
  data: () => ({
    address: "",
    isChecked: false,
    res: 0,
  }),
  methods: {
    async send() {
      const abi = storageContract.methods.checkAccess(this.address).encodeABI();
      const res = await window.ethereum.request({
        method: 'eth_call',
        params: [{
          from: window.ethereum.selectedAddress,
          to: STORAGE_ADDRESS,
          amount: '0x0',
          data: abi
        }, "latest"],
      });
      this.res = web3.utils.hexToNumber(res)
      this.isChecked = true
    }
  }
}
</script>
