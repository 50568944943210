<template>
  <div>
    <p v-if="!isMetamaskInstalled">Please, install metamask to use this page.</p>
    <p v-if="isMetamaskInstalled">Please, enable metamask plugin for this page by this button.
      <button @click="enableMetamask">Enter with metamask</button>
    </p>
  </div>
</template>

<script>
export default {
  name: "Metamask",
  data: () => ({
    isMetamaskInstalled: false,
    isMetamaskEnabled: false,
  }),
  created() {
    this.isMetamaskInstalled = typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask;
    if (this.isMetamaskInstalled) {
      this.isMetamaskEnabled = window.ethereum.isConnected()
    }
  },
  methods: {
    async enableMetamask() {
      await window.ethereum.request({ method: 'eth_requestAccounts' })
      this.isMetamaskEnabled = true;
    }
  }
}
</script>
