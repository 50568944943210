<template>
  <div>
    <label>
      <span>Revoke access for address(remove address from Whitelist):</span>
      <input placeholder="address" v-model="address" />
    </label>
    <button @click="send">Send</button>
  </div>
</template>

<script>
import {storageContract} from "@/web3";
import {STORAGE_ADDRESS} from "@/config";

export default {
  name: 'RevokeAccess',
  data: () => ({
    address: "",
  }),
  methods: {
    async send() {
      const abi = storageContract.methods.revokeAccess(this.address).encodeABI();
      await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [{
          from: window.ethereum.selectedAddress,
          to: STORAGE_ADDRESS,
          value: '0x00',
          data: abi
        }],
      });
    }
  }
}
</script>
