<template>
  <div>
    <div>
      <metamask />
    </div>
    <div class="container">
      <transfer-ownership-storage />
      <grant-access />
      <revoke-access />
      <check-access />
      <transfer-ownership-proxy />
      <update-storage />
      <transfer-tokens />
    </div>
  </div>
</template>

<script>
import Metamask from "@/components/Metamask";
import GrantAccess from "@/components/GrantAccess";
import RevokeAccess from "@/components/RevokeAccess";
import TransferOwnershipStorage from "@/components/TransferOwnershipStorage";
import TransferOwnershipProxy from "@/components/TransferOwnershipProxy";
import UpdateStorage from "@/components/UpdateStorage";
import TransferTokens from "@/components/TransferTokens";
import CheckAccess from "@/components/CheckAccess";

export default {
  name: 'List',
  components: {
    CheckAccess,
    Metamask,
    GrantAccess,
    RevokeAccess,
    TransferOwnershipStorage,
    TransferOwnershipProxy,
    UpdateStorage,
    TransferTokens,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container div {
  margin-top: 30px;
  width: 100%;
  max-width: 400px;
  border-top: 1px solid #999;
}
</style>
