<template>
  <div>
    <label>
      <span>Token transfer:</span>
      <input placeholder="receiver address" v-model="receiverAddress" />
      <input placeholder="token address" v-model="tokenAddress" />
      <input placeholder="amount" v-model="amount" />
    </label>
    <button @click="send">Send</button>
  </div>
</template>

<script>
import BigNumber from 'bignumber.js'

import web3, {erc20Contract, proxyContract} from "@/web3";
import {PROXY_ADDRESS} from "@/config";

BigNumber.config({ EXPONENTIAL_AT: 1e+9 })

export default {
  name: 'TransferTokens',
  data: () => ({
    receiverAddress: "",
    tokenAddress: "",
    amount: "",
  }),
  methods: {
    async send() {
      try {
        const decimalsABI = erc20Contract.methods.decimals().encodeABI();
        const rawDecimals = await window.ethereum.request({
          method: "eth_call",
          params: [{
            from: window.ethereum.selectedAddress,
            to: this.tokenAddress,
            data: decimalsABI
          }]
        });

        const decimals = web3.utils.hexToNumber(rawDecimals);
        const amount = new BigNumber(this.amount).multipliedBy(new BigNumber(10).pow(decimals));

        const abi = proxyContract.methods.transferTokens(this.tokenAddress, this.receiverAddress, web3.eth.abi.encodeParameter('uint256', amount.toString())).encodeABI();
        await window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [{
            from: window.ethereum.selectedAddress,
            to: PROXY_ADDRESS,
            value: '0x00',
            data: abi
          }],
        });
      } catch(e) {
        console.error(e)
      }
    }
  }
}
</script>

<style>
label {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

input {
  margin-top: 10px;
}

button {
  margin-top: 10px;
}
</style>
