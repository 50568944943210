import Web3 from "web3";

import storage from "@/abi/storage";
import proxy from "@/abi/proxy";
import erc20 from "@/abi/erc20";

const web3 = new Web3();

export const storageContract = new web3.eth.Contract(storage);
export const proxyContract = new web3.eth.Contract(proxy);
export const erc20Contract = new web3.eth.Contract(erc20);

export default web3;
