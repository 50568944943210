<template>
  <div>
    <label>
      <span>Transfer ownership for storage</span>
      <span style="color: red">(BE CAREFUL, OPERATION CAN'T BE REVERTED FROM THIS ADDRESS):</span>
      <input placeholder="address" v-model="address" />
    </label>
    <button @click="send">Send</button>
  </div>
</template>

<script>
import {storageContract} from "@/web3";
import {STORAGE_ADDRESS} from "@/config";

export default {
  name: 'TransferOwnershipStorage',
  data: () => ({
    address: "",
  }),
  methods: {
    async send() {
      const abi = storageContract.methods.transferOwnership(this.address).encodeABI();
      await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [{
          from: window.ethereum.selectedAddress,
          to: STORAGE_ADDRESS,
          value: '0x00',
          data: abi
        }],
      });
    }
  }
}
</script>

<style>
label {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

input {
  margin-top: 10px;
}

button {
  margin-top: 10px;
}
</style>
