<template>
  <div>
    <label>
      <span>Update storage address:</span>
      <input placeholder="address" v-model="address" />
    </label>
    <button @click="send">Send</button>
  </div>
</template>

<script>
import {proxyContract} from "@/web3";
import {PROXY_ADDRESS} from "@/config";

export default {
  name: 'UpdateStorage',
  data: () => ({
    address: "",
  }),
  methods: {
    async send() {
      const abi = proxyContract.methods.updateStorage(this.address).encodeABI();
      await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [{
          from: window.ethereum.selectedAddress,
          to: PROXY_ADDRESS,
          value: '0x00',
          data: abi
        }],
      });
    }
  }
}
</script>

<style>
label {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

input {
  margin-top: 10px;
}

button {
  margin-top: 10px;
}
</style>
